<template>
  <div class="popular-tags">
    <TagLink
      v-for="tag in tags"
      class="popular-tags__item"
      :class="{ hidden: !isShownTags }"
      :tag="tag"
    >
      {{ tag.name }}
    </TagLink>
    <button
      v-if="tags.length > 6"
      class="popular-tags__button"
      aria-label="show more tags"
      type="button"
      @click.prevent="toggleShowTags"
    >
      {{ isShownTags ? _T("@Hide") : `${_T("@More")} +${tags.length - 6}` }}
    </button>
  </div>
</template>

<script setup>
import TagLink from "~/nuxt_modules/blog/ui/components/TagLink.vue";

const props = defineProps({
  tags: { type: Array, required: true },
});

const isShownTags = ref(false);

const toggleShowTags = () => {
  isShownTags.value = !isShownTags.value;
};
</script>

<style scoped lang="scss">
.popular-tags {
  width: 100%;

  @include flex-container(row, flex-start, center);
  flex-wrap: wrap;
  gap: 8px;

  &__item {
    @include font(12, 16);
    color: white;

    background-color: var(--color-blue-dark);
    border-radius: 30px;

    padding: 4px 16px;

    &.hidden:nth-child(n + 7) {
      display: none;
    }
  }

  &__button {
    @include font(12, 16);
    text-align: center;
    color: var(--color-blue-dark);

    border-radius: 30px;
    background-color: var(--color-blue-lightest);

    padding: 4px 16px;
  }
}
</style>
